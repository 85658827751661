<template>
  <HeroBg
    heroTitle="Elite offshore recruitment talent to support  <br class='hidden xl:inline-block' /> your hiring team"
    heroDesc="CareerBuddy embeds the best offshore Recruiters into your company. Scale  <br class='hidden xl:inline-block' /> your business faster, smarter and save you more than 70% in Hiring Fees.."
    heroBtnText="Get Started"
    heroBtnLink="/contact"
    heroImg="hero8.png"
  />

  <Customers />

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-[5rem]">
        <h2 class="text-center text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold mb-8">Hear what companies are saying</h2>

        <div class="px-8 mb-8">
          <div class="h-[2px] bg-brand-black"></div>
        </div>

        <div class="md:flex gap-10 pb-5 justify-center">
          <div class="lg:w-1/3 bg-white rounded-3xl px-6 py-8 mb-3 md:mb:0">
            <div class="flex items-center mb-4">
              <img src="@/assets/images/testify4.png" class="w-[50px] h-[50px] mr-3" alt="" />
              <h3 class="font-medium text-[15px] pt-2">AAMR, HEAD OF STRATEGY OF REMOTEPASS</h3>
            </div>

            <p class="text-brand-black font-light">
              “I can confidently say that partnering with CareerBuddy's Embedded Talent Partner service has been a game-changer for our recruitment
              process at Remotepass. As a remote company, it can be challenging to find the right talent in different parts of the world. But, with
              CareerBuddy's help, we've been able to streamline our hiring process, reduce our costs, and find high-quality candidates quickly and
              efficiently."
            </p>
          </div>

          <div class="lg:w-1/3 bg-white rounded-3xl px-6 py-8 mb-3 md:mb:0">
            <div class="flex items-center mb-4">
              <img src="@/assets/images/testify5.png" class="w-[50px] h-[50px] mr-3" alt="" />
              <h3 class="font-medium text-[15px] pt-2">EBI, HR DIRECTOR OF SABI</h3>
            </div>

            <p class="text-brand-black font-light">
              “Finding top talent in a highly competitive market can be daunting, but with CareerBuddy's help, we were able to attract and hire the
              best fit for our team. Their professionalism and attention to detail ensured we got candidates that matched our company's culture and
              values”
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw]">
      <div class="py-[5rem]">
        <h2 class="text-center mb-8 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">Our Global Outsourcing Offering</h2>

        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-8 pb-5">
          <div class="mb-4 md:mb-0 bg-light-yellow rounded-lg p-8">
            <div class="w-[42px] h-[42px] rounded-lg bg-white flex justify-center items-center mb-4">
              <img src="@/assets/images/icons/price.png" class="w-[24px] h-[24px]" alt="" />
            </div>
            <p class="font-bold text-[18px] md:text-[25px] mb-4 leading-[100%]">Cost Benefits</p>
            <p class="text-[15px] md:text-[17px] leading-[120%] text-brand-black mb-8">
              Save up to 80% in hiring cost and paying full-time in house recruiter. Our service offers huge savings per hire versus traditional
              recruitment services. Our flexible plans help you hire when demand is high
            </p>
          </div>

          <div class="mb-4 md:mb-0 bg-light-yellow rounded-lg p-8">
            <div class="w-[42px] h-[42px] rounded-lg bg-white flex justify-center items-center mb-4">
              <img src="@/assets/images/icons/spring.png" class="w-[24px] h-[24px]" alt="" />
            </div>
            <p class="font-bold text-[18px] md:text-[25px] mb-4 leading-[100%]">Maximum Flexibility</p>
            <p class="text-[15px] md:text-[17px] leading-[120%] text-brand-black mb-8">
              Short-term 12-week contracts are available. Our team can jump in when needed and leave once roles are filled.
            </p>
          </div>

          <div class="mb-4 md:mb-0 bg-light-yellow rounded-lg p-8">
            <div class="w-[42px] h-[42px] rounded-lg bg-white flex justify-center items-center mb-4">
              <img src="@/assets/images/icons/user.png" class="w-[24px] h-[24px]" alt="" />
            </div>
            <p class="font-bold text-[18px] md:text-[25px] mb-4 leading-[100%]">Better Candidates</p>
            <p class="text-[15px] md:text-[17px] leading-[120%] text-brand-black mb-8">
              As a CareerBuddy Talent Partner works internally with you, they have a far better understanding of your Hiring Manager’s needs as well
              as your company fit, resulting in hiring better talent who will stay with you longer
            </p>
          </div>

          <div class="mb-4 md:mb-0 bg-light-yellow rounded-lg p-8">
            <div class="w-[42px] h-[42px] rounded-lg bg-white flex justify-center items-center mb-4">
              <img src="@/assets/images/icons/adjust.png" class="w-[24px] h-[24px]" alt="" />
            </div>
            <p class="font-bold text-[18px] md:text-[25px] mb-4 leading-[100%]">More Control</p>
            <p class="text-[15px] md:text-[17px] leading-[120%] text-brand-black mb-8">
              We combine our technical expertise & specialist support teams with winning technology-led strategies to help you scale. Receive
              pre-screened, vetted, and qualified candidates ready to go
            </p>
          </div>

          <div class="mb-4 md:mb-0 bg-light-yellow rounded-lg p-8">
            <div class="w-[42px] h-[42px] rounded-lg bg-white flex justify-center items-center mb-4">
              <img src="@/assets/images/icons/engineering.png" class="w-[24px] h-[24px]" alt="" />
            </div>
            <p class="font-bold text-[18px] md:text-[25px] mb-4 leading-[100%]">Leading Technology</p>
            <p class="text-[18px] md:text-[17px] leading-[120%] text-brand-black mb-8">
              We combine our technical expertise & specialist support teams with winning technology-led strategies to help you scale. Receive
              pre-screened, vetted, and qualified candidates ready to go
            </p>
          </div>

          <div class="mb-4 md:mb-0 bg-light-yellow rounded-lg p-8">
            <div class="w-[42px] h-[42px] rounded-lg bg-white flex justify-center items-center mb-4">
              <img src="@/assets/images/icons/people.png" class="w-[24px] h-[24px]" alt="" />
            </div>
            <p class="font-bold text-[18px] md:text-[25px] mb-4 leading-[100%]">Embedded Teams</p>
            <p class="text-[15px] md:text-[17px] leading-[120%] text-brand-black mb-8">
              Unlike traditional recruitment specialists, our Talent Partners work as an extended part of your HR team. We integrate seamlessly with
              existing teams and key stakeholders. We help you build better, more diverse recruitment campaigns.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full md:w-[84vw] px-[24px] lg:px-0">
      <div class="py-[5rem] pb-[0rem] lg:pb-[5rem]">
        <div class="grid-cols-1 lg:grid lg:grid-cols-2 gap-16 items-end pb-5">
          <div>
            <h1 class="text-light-grey text-[30px] md:text-[60px] leading-[110%] font-medium mb-4">
              The key to success? <br class="hidden md:inline-block" />
              Our “embed model”
            </h1>
            <p class="text-[18px] md:text-[25px]">We are not only providing talent, we are fully embedding a new team member into your company</p>
          </div>
          <div class="hidden lg:inline-block">
            <img src="@/assets/images/embed.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw]">
      <div class="py-[5rem] pt-[1rem] md:pt-[5rem]">
        <div class="md:grid md:grid-cols-2 gap-8 pb-5">
          <div class="mb-4 md:mb-0 bg-white rounded-lg p-8">
            <img src="@/assets/images/icons/time.png" class="w-[62px] h-[62px]" alt="" />
            <p class="text-[18px] leading-[110%] text-brand-black my-4 font-bold">Full TIme</p>
            <p class="text-[15px] leading-[120%] text-brand-black mb-4">
              Tell us about the role you're looking to fill. We'll get to know each other and the job you're looking to fill
            </p>
          </div>

          <div class="mb-4 md:mb-0 bg-white rounded-lg p-8">
            <img src="@/assets/images/icons/globe.png" class="w-[62px] h-[62px]" alt="" />
            <p class="text-[18px] leading-[110%] text-brand-black my-4 font-bold">In your time zone</p>
            <p class="text-[15px] leading-[120%] text-brand-black mb-4">They work during your normal business hours – despite the time difference.</p>
          </div>

          <div class="mb-4 md:mb-0 bg-white rounded-lg p-8">
            <img src="@/assets/images/icons/hand.png" class="w-[62px] h-[62px]" alt="" />
            <p class="text-[18px] leading-[110%] text-brand-black my-4 font-bold">As a “normal” team member</p>
            <p class="text-[15px] leading-[120%] text-brand-black mb-4">
              Give them access to your company email, Slack, Zoom etc. And don’t forget to invite them to your virtual happy hour!
            </p>
          </div>

          <div class="mb-4 md:mb-0 bg-white rounded-lg p-8">
            <img src="@/assets/images/icons/handshake.png" class="w-[62px] h-[62px]" alt="" />
            <p class="text-[18px] leading-[110%] text-brand-black my-4 font-bold">With plenty of support to get it right</p>
            <p class="text-[15px] leading-[120%] text-brand-black mb-4">
              Using global talent might be scary initially. But we got you. Ongoing support without limits! We only win if you win.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full lg:w-[72vw]">
      <div class="py-[5rem]">
        <div class="px-[24px] md:px-0">
          <h2 class="text-center text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">How it works</h2>
          <p class="text-light-grey/50 text-[18px] md:text-[30px] text-center mb-[3rem]">
            When you partner with CareerBuddy you do not have to evaluate resumes or participate in ANY interviews – we own this process 100%!
          </p>
        </div>

        <div>
          <div class="bg-white rounded-2xl p-8 mb-6">
            <div class="md:flex justify-between items-center gap-x-16 px-8 py-4">
              <div class="mb-3 md:mb-0">
                <span class="px-4 py-3 bg-[#C1FFE9]/[.79] rounded-md inline-block text-[16px] text-brand-black/50 font-semibold mb-3"
                  >STEP 1 of 3</span
                >
                <h1 class="text-brand-black my-3 mb-1 font-bold text-[18px] md:text-[28px]">Speak to our team</h1>
                <p class="leading-[130%] text-[14px] md:text-[16px] text-brand-black">
                  Hit the inquire button. Tell us about your requirement. We'll get to know each other and thetype of Recruiter or Sourcer you're
                  looking to bring onboard.
                </p>

                <div class="mt-5">
                  <a href="" class="rounded-3xl px-6 md:px-16 py-4 text-center bg-brand-black text-white inline-flex items-center">
                    <span class="mr-2">Get Started</span>
                    <img src="@/assets/icons/arrow-right.svg" alt="" />
                  </a>
                </div>
              </div>

              <img src="@/assets/images/team.png" alt="" />
            </div>
          </div>

          <div class="bg-white rounded-2xl p-8 mb-6">
            <div class="md:flex justify-between items-center gap-x-16 px-8 py-4">
              <div class="mb-3 md:mb-0">
                <span class="px-4 py-3 bg-[#C1FFE9]/[.79] rounded-md inline-block text-[16px] text-brand-black/50 font-semibold mb-3"
                  >STEP 2 of 3</span
                >
                <h1 class="text-brand-black my-3 mb-1 font-bold text-[18px] md:text-[28px]">Get matched with your recruiter</h1>
                <p class="leading-[130%] text-[14px] md:text-[16px] text-brand-black">
                  We connect you with Recruiters who are members of our team who fit your exact needs, working hours,. You can decide if you want to
                  conduct extra screening
                </p>

                <div class="mt-5">
                  <a href="" class="rounded-3xl px-6 md:px-16 py-4 text-center bg-brand-black text-white inline-flex items-center">
                    <span class="mr-2">Get Started</span>
                    <img src="@/assets/icons/arrow-right.svg" alt="" />
                  </a>
                </div>
              </div>

              <img src="@/assets/images/recruitment.png" alt="" />
            </div>
          </div>

          <div class="bg-white rounded-2xl p-8">
            <div class="md:flex justify-between items-center gap-x-16 px-8 py-4">
              <div class="mb-3 md:mb-0">
                <span class="px-4 py-3 bg-[#C1FFE9]/[.79] rounded-md inline-block text-[16px] text-brand-black/50 font-semibold mb-3"
                  >STEP 3 of 3</span
                >
                <h1 class="text-brand-black my-3 mb-1 font-bold text-[18px] md:text-[28px]">Meet your recruiter</h1>
                <p class="leading-[130%] text-[14px] md:text-[16px] text-brand-black">
                  And get growing together! Your new Recruiter is fully embedded as a full-time member of your team
                </p>

                <div class="mt-5">
                  <a href="" class="rounded-3xl px-6 md:px-16 py-4 text-center bg-brand-black text-white inline-flex items-center">
                    <span class="mr-2">Get Started</span>
                    <img src="@/assets/icons/arrow-right.svg" alt="" />
                  </a>
                </div>
              </div>

              <img src="@/assets/images/meeting.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <faqs :faqs="faqs" />
</template>

<script setup>
import { ref } from 'vue';
import Customers from '@/components/partials/Customers.vue';
import HeroBg from '@/components/partials/HeroBg.vue';
import Faqs from '@/components/partials/Faqs.vue';
import { useMeta } from '@/utils/meta';

const faqs = ref([
  {
    title: 'How does the Embedded Talent Partner service work?',
    desc: `<p>Our Talent Acquisition Specialists work in partnership with your HR team. We manage the recruiter on your behalf, ensuring consistency of service at all times. We agree on deliverables ahead of time and we deliver what we promise.</p>

    <p>Talent Acquisition Specialists focus on sourcing, screening, managing inbound applications, candidate management, and screen on average between 10-20 candidates per role. We manage the recruiter on your behalf, ensuring consistency of service at all times.</p>

      <p>Embedded Talent Partners plugin to your talent team. They are focused on your delivery 9am-6pm Monday-Friday. You’ll find they’ll help you hire better talent, faster, whilst eradicating agency fees.</p>`,
  },
  {
    title: 'How Does the Embedded Talent Partner differ from your regular Hiring Service',
    desc: `<p>This model differs based on how you pay. With our Hiring Service, you pay a commission on success. While this removes risk, it is expensive. With the Embedded Talent Partner, you get the same quality of service at a fraction of the cost.</p>

    <p>We help Business leaders and HR managers succeed. We offer a dedicated monthly subscription model, allowing you to scale quickly by freeing you up to do what you do best.</p>`,
  },
  {
    title: "What's your payment structure?",
    desc: `<p>We charge an all-inclusive flat monthly subscription with no additional fees upon placement. The benefit to our clients includes accurate forecasting, increased budget efficiencies, less downtime, on-demand expertise, and resources. </p>

    <p>Our Embedded Partner supports you when you need it, how you need it, and where you need it. Our model makes it easier for clients to plan ahead and ensures we are working in line with your business goals.</p>`,
  },
]);

useMeta();
</script>

<style lang="scss" scoped>
.faq-desc::v-deep {
  p {
    line-height: 25.999px !important;
    font-size: 16px !important;
    margin-bottom: 1rem;
  }
}
</style>
